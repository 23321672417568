import React from 'react';

function Header() {
  return (
    <header className='d-flex justify-content-center'>
          <h1 className='p-5'>A+I Lesson Plans</h1>
    </header>
  );
}

export default Header;